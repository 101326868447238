<template>
		<el-form-item v-if="!justDownLoad" :label="label">
			<el-upload style="display: flex;"
						class="upload-demo"
						ref="upload"
						action="string"
						:http-request="uploadFile"
						:on-preview="handlePreview"
						:on-remove="handleRemove"
						:file-list="fileList"
						:limit="limit"
						:auto-upload="true">
						<el-button :disabled="disabled" slot="trigger" size="small" type="primary">选取文件</el-button>
			</el-upload>
		</el-form-item>
		<el-upload
				v-else
				style="display: flex;"
				class="upload-demo"
				ref="upload"
				action="string"
				:http-request="uploadFile"
				:on-preview="handlePreview"
				:file-list="fileList">
				{{justDownLoadContent}}
		</el-upload>
</template>

<script>
import {uploadFile} from "@/api/file";

export default {
		name: "MyUploadFileFormItem",
		model: {
				prop: 'fileList',
				event: 'change'
		},
		props: {
				label: {
						type: String,
						default: '文件:'
				},
				subDir:{
					type:String,
					default:''
				},
				// action: {
				// 		type: String,
				// 		default: '/api/FIle/UploadFile/'
				// },
				limit: {
						type: Number,
						default: 5
				},
				justDownLoad: Boolean,
				disabled:Boolean,
				fileList: {
						type: Array,
						default() {
								return [];
						}
				},
		},
		computed: {
				justDownLoadContent() {					
					return this.fileList?.length ? '' : '无';
				}
		},
		methods: {
				handleRemove(file, fileList) {
						this.fileList.splice(0, this.fileList.length, ...fileList)
						this.$emit('change', this.fileList);
				},
				async handlePreview(file) {
						console.log(file);
						document.location.href = file.url
						// const result = await downloadFile({fileName: file.name});
						// console.log('handlePreview',result);
				},
				// handleSuccess(response, file, fileList) {
				// 		console.log('handleSuccess', response, file, fileList);
				// 		this.$emit('change',)
				//
				// },
				handleExceed(files, fileList) {
						this.$message.warning(`当前限制选择 ${limit} 个文件，本次选择了 ${files.length} 个文件`);//，共选择了 ${files.length + fileList.length} 个文件`);
				},
				beforeRemove(file, fileList) {
						return this.$confirm(`确定移除 ${file.name}？`);
				},
				async uploadFile(param) {
						const formData = new FormData()
						formData.append('fileInfo', param.file);
						// const {data} = await uploadFile({formData});
						const {data} = await uploadFile({formData},this.subDir);
						this.fileList.push(data);
						// console.log('uploadFile', this.fileList);
						this.$emit('change', this.fileList);
				},
		}
}
</script>

<style scoped>
.upload-demo{
    display: flex;
}
</style>