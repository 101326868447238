<template>
		<el-col align="end" style="margin-bottom: 30px;">
				<el-table :data="data.slice((currentPage-1)*sizePrePage,currentPage*sizePrePage)"
									ref="commonTable"
									border
									:row-key="rowKey"
									:default-sort="defaultSort"
									@sort-change="sortChange"
									:highlight-current-row="highlightCurrentRow"
									:row-style="tableRowClassName"
									@current-change="$emit('current-change',$event)"
									@selection-change="$emit('selection-change',$event)"
									:header-cell-style="{background:'#eef1f6',color:'#606266'}">
						<slot></slot>
				</el-table>
				<el-row type="flex" justify="end" align="middle">
					<!-- :current-page.sync="currentPage" -->
						<el-pagination
								v-if="!showMoreBtn"
								v-model:current-page="currentPage"
								
								:page-size="sizePrePage"
								layout="total, prev, pager, next"
								:disabled="false"
								:total="data.length">
						</el-pagination>
						<el-button v-else @click="onClickMore" type="text" style="margin: 10px 10px;">更多...</el-button>
						<div v-if="!!cornerText" style="margin: 0 10px;">{{cornerText}}</div>
				</el-row>
		</el-col>

</template>

<script>
export default {
		name: "CommonTable",
		props: {
				data: {
						type: Array,
						default() {
								return [];
						},
						required: true
				},
				rowKey: {
						type: Function,
						default(row) {
								return row.id;
						}
				},
				defaultSort: {
						type: Object,
						default() {
								return {};
						}
				},
				cornerText: String,
				highlightCurrentRow: Boolean,
				sizePrePage: {
						type: Number,
						default: 10,
				},
				showMoreBtn: {
						type: Boolean,
						default: false
				}
		},
		watch: {
				currentPage(newValue) {
						console.log('currentPage', newValue);
				},
				sizePrePage(newValue) {
						console.log('sizePrePage', newValue);
				},

		},
		created() {
			
		},
		data() {
				return {
						currentPage: 1,
				};
		},
		methods: {
			clearSelect(){
				// console.log('aa',this.$refs.commonTable);
				this.$refs.commonTable.clearSelection();
			},
			
				sortChange({column, prop, order}) {
						console.log('sortChange', column, prop, order);
						if (order === "ascending") {
								if (typeof this.data[0]?.[prop] === "number") {

										this.data = this.data.sort((a, b) => a[prop] - b[prop]);
								} else {

										this.data = this.data.sort((a, b) => a[prop]?.toString().localeCompare(b[prop]?.toString(), "zh") ?? 0);
								}
						} else {
								if (typeof this.data[0]?.[prop] === "number") {

										this.data = this.data.sort((a, b) => b[prop] - a[prop]);
								} else {

										this.data = this.data.sort((a, b) => b[prop]?.toString().localeCompare(a[prop]?.toString(), "zh") ?? 0);
								}

						}
				},

				onClickMore() {
					this.$emit('more');
				},

				tableRowClassName({row,rowIndex}){
					// // console.log('rowIndex',rowIndex);
					// if (rowIndex % 2 !== 1){
					// 	return 'success-row'
					// }
					// return ''
					// console.log('row',row)
					// console.log('row.状态1',row.状态)
					let styleJson={};
					if(row.状态==="修改"){
						styleJson={"color":"#CC6600"};
					}else if (row.状态==="新增"){
						styleJson={"color":"red"};
					}else{
						styleJson={};
					}
					// console.log('row.状态',row,row.状态,styleJson)
					return styleJson;
				}
		}
}
</script>

<style scoped>
.success-row{
  /* background:rgb(24, 27, 27); */
}
</style>