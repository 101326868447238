<template>
    <div id="data-view" class ="curContainer">
        <el-row type="flex" justify="end" style="margin-top:5px;">                   
            <el-button type="primary" @click="onItemNew()" style="margin-right:10px;">新建</el-button>
        </el-row>
      <el-form inline :model="searchForm" label-width="140px" style="margin-top: 30px;">
        <el-form-item label="选择站点">
            <el-cascader
                :options="areaStationList"
                :show-all-levels="false"    
                :props="props"
                v-model="searchForm.stationArray"
                collapse-tags               
                clearable></el-cascader>
        </el-form-item>
        <el-form-item label="关键字">
            <el-input v-model="searchForm.key" autocomplete="off"></el-input>
        </el-form-item>
        <el-button @click="onSearch" type="primary">搜索</el-button>
      </el-form>

      <common-table ref="tableObj"  :data="tableObj" border 
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                style="width: 100%;margin-top: 10px;" :row-key="getRowKey" stripe>                
                <el-table-column prop="ydarea" align="center" label="大区" ></el-table-column>
                <el-table-column prop="province" align="center" label="省" ></el-table-column>
                <el-table-column prop="city" align="center" label="市" ></el-table-column>
                <el-table-column prop="station" align="center" label="车站" >
                    <template  v-slot="scope">
                        <el-button type="text" @click="onItemEditClick(scope.row)">{{scope.row.station}}</el-button>  
                    </template>
                </el-table-column>
      </common-table>

      <el-dialog title="新建/修改车站" v-model="editDialogVisible" width="60%" @open="open()"
                                              :modal-append-to-body='false'
                                              :close-on-click-modal='false'
                                              append-to-body>
                  <div>
                    <el-form  ref="form" :model="editForm" label-width="138px" style="margin-top:20px;" label-position="right">
                    <!-- <el-row>                         -->
                        <el-form-item label="车站名称"  >
                            <el-input  v-model="editForm.station" style="width:220px" placeholder="请输入车站名称"></el-input>
                        </el-form-item>
                        <el-form-item label="城市">
                          <el-cascader
                              :options="AreaProvinceCityList"
                              :show-all-levels="false"    
                              :props="{multiple: false}"
                              v-model="editForm.cityId"
                              collapse-tags
                          ></el-cascader>
                        </el-form-item>
                        <el-form-item label="车站等级"  >
                            <el-select v-model="editForm.gradeId" clearable placeholder="请选择车站等级">
                            <el-option
                                v-for="item in stationGradeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                            </el-select>
                        </el-form-item>
                        <div class="text-ele">
                          <my-upload-file-form-item label="车站外观图" :limit="1"  :subDir="'车站外观'" :file-list="editForm.wgImgArr" v-model="editForm.wgImgArr"/>                                    
                        </div>    
                        <div class="text-ele">
                          <my-upload-file-form-item label="运行线路图" :limit="1"  :subDir="'运行线路'" :file-list="editForm.xlImgArr" v-model="editForm.xlImgArr"/>                                    
                        </div>    

                        <el-form-item label="车站介绍" >
                            <el-input  v-model="editForm.describe1" placeholder="请输入车站介绍">{{editForm.describe1}}</el-input>
                        </el-form-item>
                        <el-form-item label="车站面积" >
                            <el-input  v-model="editForm.describe3" style="width:220px" placeholder="请输入车站面积">{{editForm.describe3}}</el-input>
                        </el-form-item>
                        <el-form-item label="站台规模" >
                            <el-input  v-model="editForm.describe4" style="width:220px" placeholder="请输入站台规模">{{editForm.describe4}}</el-input>
                        </el-form-item>
                        <el-form-item label="地铁线路" >
                            <el-input  v-model="editForm.describe5" placeholder="请输入地铁线路">{{editForm.describe5}}</el-input>
                        </el-form-item>
                        <el-form-item label="主要线路" >
                            <el-input  v-model="editForm.describe6" placeholder="请输入主要线路">{{editForm.describe6}}</el-input>
                        </el-form-item>
                        <el-form-item label="所属路局" >
                            <el-input  v-model="editForm.describe7" style="width:220px" placeholder="请输入所属路局">{{editForm.describe7}}</el-input>
                        </el-form-item>
                        <el-form-item label="年发送旅客量" >
                            <el-input  v-model="editForm.describe8" style="width:220px" placeholder="请输入年发送旅客量">{{editForm.describe8}}</el-input>
                        </el-form-item>
                        <el-form-item label="节假日日均客流量" >
                            <el-input  v-model="editForm.describe9" style="width:220px" placeholder="请输入节假日日均客流量">{{editForm.describe9}}</el-input>
                        </el-form-item>
                        <el-form-item label="平日日均客流量" >
                            <el-input  v-model="editForm.describe10" style="width:220px" placeholder="请输入平日日均客流量">{{editForm.describe10}}</el-input>
                        </el-form-item>
                        <div class="text-ele">
                          <!-- <my-upload-file-form-item label="站层平面图" :limit="10"  :subDir="'站层平面'" :file-list="editForm.zcImgArr" v-model="editForm.zcImgArr"/>                                     -->
                          <el-form-item label="站层平面图" >
                            <el-upload action="#" list-type="picture-card" :auto-upload="false">
                                    <i slot="default" class="el-icon-plus"></i>
                                    <div slot="file" slot-scope="{file}">
                                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                                        <span class="el-upload-list__item-actions">
                                            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                                <i class="el-icon-zoom-in"></i>
                                            </span>
                                            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                                                <i class="el-icon-download"></i>
                                            </span>
                                            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                                                <i class="el-icon-delete"></i>
                                            </span>
                                        </span>
                                    </div>
                                </el-upload>
                            </el-form-item>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </div>  

                        <el-form-item label="覆盖城市">
                          <el-cascader
                              :options="AreaProvinceCityList"
                              :show-all-levels="false"    
                              :props="{multiple: true}"
                              v-model="editForm.coverCityArr"
                              collapse-tags
                          ></el-cascader>
                        </el-form-item>
                </el-form>                         
                  </div>
                  <div class="button-footer">                      
                      <span >
                          <el-button type="primary" @click="onSaveModify()">确定</el-button>
                          <el-button @click="editDialogVisible = false">关闭</el-button>
                      </span>                    
                  </div>
              </el-dialog>   


    </div>
</template>


<script>
import CommonTable from "@/components/CommonTable";
import { mapGetters } from "vuex";
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";

export default {
    components: {
        // vueQr
    },
    components: { CommonTable,MyUploadFileFormItem}, 
    computed: { ...mapGetters(["areaStationList","GetMgrStationList","AreaProvinceCityList","stationGradeList"]) },

    data() {
        return {
            props: { multiple: true },
            searchForm:{
                stationArray:[],
                key:'',
            },
            tableObj:[],
            editDialogVisible:false,
            editForm:{
                station:'',
                cityId:'',
                gradeId:'',
                wgImgArr:[],
                xlImgArr:[],
                describe1:'',
                describe3:'',
                describe4:'',
                describe5:'',
                describe6:'',
                describe7:'',
                describe8:'',
                describe9:'',
                describe10:'',
                zcImgArr:[],
                coverCityArr:[],
            },


            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,

            file:{url:''}
        };
    },
    watch: {},
    $route: {

    },
    async mounted(){
        await this.$store.dispatch('operate/areaStationList');
        await this.$store.dispatch('operate/AreaProvinceCityList');
        await this.$store.dispatch('operate/stationGradeList');
        
        await this.onSearch();
    },

    methods: {
        async onItemEditClick(item){

        },

        async onItemNew(){
            this.editForm.station='';
            this.editForm.cityId='';
            this.editForm.gradeId='';
            this.editForm.wgImgArr=[];
            this.editForm.xlImgArr=[];
            this.editForm.describe1='';
            this.editForm.describe3='';
            this.editForm.describe4='';
            this.editForm.describe5='';
            this.editForm.describe6='';
            this.editForm.describe7='';
            this.editForm.describe8='';
            this.editForm.describe9='';
            this.editForm.describe10='';
            this.editForm.zcImgArr=[];
            this.editForm.coverCityArr=[];
            this.editDialogVisible=true;
        },

       async onSearch() {
        var stationIds='';
        if (this.searchForm.stationArray.length>0){
            stationIds=this.searchForm.stationArray.join(',');
        }
        await this.$store.dispatch("operate/GetMgrStationList", {
            stationIds,
            key: this.searchForm.key,
            }).then(res=>{
                
                this.tableObj=this.GetMgrStationList;
                // this.$refs.tableObj.clearSelect();
                // console.log('aaa',this.tableObj)
            });
        },

        handleRemove(file) {
            console.log('handleRemove',file);
        },
        handlePictureCardPreview(file) {
            console.log('handlePictureCardPreview',file);
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log('handleDownload',file);
        },

        open() {
            // console.log("do open");
            this.$nextTick(() => {
                //  执行echarts方法
            });
        },
       
    }
};
</script>

<style lang="less">
  .curContainer {
    /* padding: 30px; */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}
</style>